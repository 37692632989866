input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.Form {
  animation: fade 200ms ease-out;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: white;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.FormRow:first-child {
  border-top: none;
}

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #5131e2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #fce883;
  /* Hack to hide the default webkit autofill */
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.FormRowInput {
  border-style: none;
  border-radius: 8px;
  font-size: 1.1rem;
  padding: 1rem 0.5rem;
  line-height: 1.25em;
  background-color: transparent;
  animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
  color: #87bbfd;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.CardField-input-wrapper {
  color: black;
}

.StripeElement {
  width: 100%;
  color: black;
  font-size: 1.25rem;
  padding: 1rem 0.5rem;
  line-height: 1.25em;
}

.SubmitButton {
  width: 100%;
  align-content: center;
  justify-content: center;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  cursor: pointer;
  transition: 160ms all;
  display: block;
  border-style: solid;
  background: none;
  color: white;
  border-color: white;
  border-radius: 6px;
  line-height: 1.4em;
  padding: 1rem 2rem;
}

.SubmitButton:active {
  border-color: #eee;
  transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #7795f8;
  box-shadow: none;
}

.ErrorMessage {
  color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}
