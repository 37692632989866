.Container {
  display: flex;
  width: 300px;
  flex: 0 0 auto;
  border: 1px solid #fbfbfb;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 23, 36, 0.15);

  padding: 0.5rem 0.5rem;
  margin-bottom: 1.25rem;

  transition: 160ms all;
  background-color: white;
  color: #4C2EE0;
  flex-direction: column;
  align-self: stretch;
}

.Container:hover,
.Container:active {
  background-color: #fbfbfb;
  cursor: pointer;
}


.ImageContainer {
  height: 250px;
  object-fit: cover;
}
