.FoodItems {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-x: auto;
}

@media only screen and (max-width : 700px) {
  .FoodItems {
    justify-content: flex-start;
    overflow-x: scroll;
    max-width: 90vw;
  }
}

.FoodItems > div:not(:last-child) {
  margin-right: 1rem;
}
