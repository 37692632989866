/**
 * Via Figma’s css generator
 */
.CardItem {
  border: 1px solid #fbfbfb;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 23, 36, 0.15);

  padding: 1rem 0.5rem;
  margin-bottom: 1.25rem;

  transition: 160ms all;
  background-color: white;
  color: #4C2EE0;
  flex: 1 1 auto;

  min-width: 280px;
}

@media screen and (min-width: 700px) {
  .CardItem {
    margin-right: 1rem;
  }
}


.CardItem:hover,
.CardItem:active {
  background-color: #fbfbfb;
  cursor: pointer;
}

.CardItem--TwoColumns {
  display: flex;
}
