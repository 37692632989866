.VideoPreviewFrame {
  margin: 20px auto;
  display: block;
  text-align: center;
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.RectNormal {
  width: 498px;
  height: 280px;
}

.RectSmall {
  width: 320px;
  height: 180px;
}

.Rounded {
  border-radius: 150px;
}

.BoxNormal {
  width: 280px;
  height: 280px;
  border-radius: 150px;
}

.BoxSmall {
  width: 180px;
  height: 180px;
  border-radius: 150px;
}


.Normal {
  position: relative;
  height: 280px;
}

.Small {
  position: relative;
  height: 180px;
}
