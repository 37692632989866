.ImageInputLabel {
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #492ECE;
  color: #fff;
  padding: 6px 12px;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.ImageInput {
  display: none;
}
