.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
}

.overlayShow {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 5s ease-in-out;
}

@media screen and (max-width: 700px) {
  .popup {
    width: 90%;
  }
}

.popupTitleBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popupTitle {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}

.popupClose {
  background: none;
  border:none;
  cursor: pointer;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popupClose:hover {
  color: #06D85F;
}

.popupContent {
  max-height: 80%;
  overflow: auto;
}

