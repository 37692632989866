
.iPhoneFrame > div {
  margin-top: 24px;
  overflow: scroll;
  height: 700px;
  width: 320px;
  scrollbar-width: thin;
}

.iPhoneFrameButton {
  position: absolute;
  top: 4px;
  right: 4px;
  opacity: 0.75;
}

@media only screen and (max-width : 500px) {
  .iPhoneFrameButton {
    display: none;
  }
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.nicescrollbar::-webkit-scrollbar {
  background-color:#fff;
  width:16px
}

/* background of the scrollbar except button or resizer */
.nicescrollbar::-webkit-scrollbar-track {
  background-color:#fff
}

/* scrollbar itself */
.nicescrollbar::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:4px solid #fff
}

/* set button(top and bottom of the scrollbar) */
.nicescrollbar::-webkit-scrollbar-button {display:none}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.logoHead {
  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}


