.Button {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  cursor: pointer;
  transition: 160ms all;
}

.Button:disabled {
  opacity: 0.75;
  cursor: not-allowed;
}

.ButtonPrimary {
  background: none;
  color: white;
  border-color: white;
  border-radius: 6px;
  line-height: 1.4em;
  padding: 1rem 2rem;
}

.ButtonPrimary:active,
.ButtonPrimary:hover {
  border-color: #eee;
}

.ButtonSecondarySmall,
.ButtonSecondary {
  background: none;
  border: none;
  color: #eeeeee;
}

.ButtonSecondarySmall:active,
.ButtonSecondarySmall:hover,
.ButtonSecondary:active,
.ButtonSecondary:hover {
  opacity: 0.8;
}

.ButtonSecondarySmall {
  font-size: 0.9rem;
}
