html, body {
  font-family: 'Montserrat', sans-serif;
  color: white;
  background: rgb(140,72,244);
  background: linear-gradient(90deg, rgba(140,72,244,1) 0%, rgba(76,46,224,1) 100%);

}

@media only screen and (max-width : 500px) {
  body {
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
}

body {
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
}

h1 {
  text-align: center;
}

