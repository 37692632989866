.videoAudioContent {
  display: none;
}
.videoLocalContent {
  position: relative;
  width: 20%;
  top: 4px;
  right: 4px;

}
.videoRemoteContent {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
}
