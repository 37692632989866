.TextInputWrapper {
  display: flex;
  flex-direction: column;
}

.TextInput {
  border: none;
  border-radius: 8px;
  font-size: 1.25rem;
  padding: 1rem 0.5rem;
  margin-top: 0.25rem;
  line-height: 1.25em;
  margin-bottom: 1rem;
}

.TextInputLabel {
  margin-bottom: 0.5rem;
}


